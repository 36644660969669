import React from "react";
import Column from "../template/Column";
import Typography from "../common/Typography";
import Image from "../common/Image";

import logo from "../../assets/otsuka.png";

const Header = ({ title, titleColor, leftImage }) => {
  return (
    <React.Fragment>
      <Column fluid paddingOnMobile={15}>
        <Column mobileWidth={"20%"} width={"15%"} align={"left"}>
          <Image width={"100%"} src={leftImage ? leftImage : logo} />
        </Column>
        <Column hideOnMobile flexCenter width={"70%"}>
          <Typography
            mobileSize={19}
            center
            titleSection
            bold
            size={20}
            color={titleColor}
          >
            {title}
          </Typography>
        </Column>
        <Column mobileWidth={"20%"} width={"15%"} align={"right"} />
      </Column>
    </React.Fragment>
  );
};

export default Header;
