import styled from "styled-components";

const Column = styled.div`
  width: 100%;
  position: relative;

  ${({ column }) =>
    column &&
    `
    display:flex;
    flex-direction:column;
  `};

  ${({ justify }) =>
  justify &&
    `
    justify-content:${justify} !important;
  `};

  ${({ width }) =>
    width &&
    `
        width : ${width};
    `};

    ${({ height }) =>
    height &&
    `
    height : ${height};
    `};

  ${({ responsive }) =>
    responsive &&
    `
    @media only screen and (max-width:980px){
      width : 100%;
    }
  `};

  ${({ justifyMobile }) => justifyMobile && `
    @media only screen and (max-width:980px){
      justify-content:${justifyMobile} !important;
    }
  `};


  ${({ flexAlign }) =>
  flexAlign &&
    `
    align-items:${flexAlign} !important;
  `};

  ${({ align }) =>
    align &&
    `
    text-align:${align};
  `};

  ${({ mobileWidth }) =>
    mobileWidth &&
    `
    @media only screen and (min-width:320px) and (max-width:980px){
      width : ${mobileWidth};
    }
  `};

  ${({ hideOnMobile }) =>
    hideOnMobile &&
    `
    @media only screen and (min-width:320px) and (max-width:980px){
      display:none;
    }
  `};

  ${({ showInMobile }) =>
    showInMobile &&
    `
    display:none;
    @media only screen and (min-width:320px) and (max-width:980px){
      display:block;
    }
  `};

  ${({ hideInDesktop }) =>
    hideInDesktop &&
    `
  @media only screen and (min-width:980px){
    display:none;
  }
  `};

  ${({ fluid }) =>
    fluid &&
    `
    display:flex;
    align-items:center;
    justify-content:space-between;
    flex:1;
    margin:15px 0px;

    @media all and (-ms-high-contrast: none) {
      & {
          margin:40px 0;
      }
    }
   `};

  ${({ flexCenter }) =>
    flexCenter &&
    `
    display:flex;
    align-items:center;
    justify-content:center;
   `};

  ${({ row }) =>
    row &&
    `
    display:flex;
    align-items:center;
    flex-direction:row;
   `};

  ${({ flexStart }) =>
    flexStart &&
    `
    display:flex;
    align-items:center;
    justify-content:flex-start;
    flex-direction:row;
   `};

  ${({ flexEnd }) =>
    flexEnd &&
    `
    display:flex;
    align-items:center;
    justify-content:flex-end;
    flex-direction:row;
   `};

  ${({ block }) =>
    block &&
    `
    background-color:#F2F2F2;
   `};

   ${({ paddingOnMobile }) =>
    paddingOnMobile &&
    `
        @media only screen and (min-width:320px) and (max-width:767px){
          padding:0 ${paddingOnMobile}px;
        }
   `};

   ${({ mobilePadding }) =>
   mobilePadding &&
    `
        @media only screen and (min-width:320px) and (max-width:800px){
          padding:${mobilePadding}px !important;
        }
   `};

   ${({ mobileMargin }) =>
   mobileMargin &&
    `
        @media only screen and (min-width:320px) and (max-width:800px){
          margin:${mobileMargin} !important;
        }
   `};

  ${({ padding }) =>
    padding &&
    `
    padding : ${padding}px;
   `};

   ${({ margin }) =>
   margin &&
    `
    margin : ${margin}px;
   `};

  ${({ flexCenterOnMobile }) =>
    flexCenterOnMobile &&
    `
        @media only screen and (max-width:980px){
          justify-content:center;
        }
   `};

  ${({ absoluteRight }) =>
    absoluteRight &&
    `
        width:auto !important;
        position: absolute !important;
        right: 20px;
        top: -80px;

        input{
          width : 65%;
        }

        @media only screen and (min-width:320px) and (max-width:767px){
          position:relative !important;
          top:20px;
          right:0;
        }
   `};

  ${({ itsAlert }) =>
    itsAlert &&
    `
        top:-75px;
  `};

  ${({ absoluteError }) =>
    absoluteError &&
    `
        top : -81px;
   `};

   ${({ flex }) => flex && `
        display:flex;
   `}
`;

export default Column;
