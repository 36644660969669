import styled from "styled-components";

const Typography = styled.span`
  font-family: Avenir-Roman;
  color: #0e264d;

  ${({ bold }) =>
    bold &&
    `
  font-family: Avenir-Black;
  `};

  ${({ medium }) =>
    medium &&
    `
  font-family: Avenir-Medium;
  `};

  ${({ size }) =>
    size &&
    `
        font-size : ${size}px;
    `};

  ${({ center }) =>
    center &&
    `
        text-align:center;
    `};

  ${({ titleSection }) =>
    titleSection &&
    `
    color : #DCA149;
   `};

  ${({ spaceLeft }) =>
    spaceLeft &&
    `
    margin-left:${spaceLeft}px;
   `};

  ${({ lineHeight }) =>
    lineHeight &&
    `
    line-height:${lineHeight}px;
   `};

  ${({ color }) =>
    color &&
    `
    color:${color} !important;
   `};

  ${({ mobileSize }) =>
    mobileSize &&
    `
    @media only screen and (min-width:320px) and (max-width:800px){
      font-size : ${mobileSize}px !important;
    }
   `};

   ${({ textAlign }) => textAlign && `text-align:${textAlign}`};
   ${({ display }) => display && `display:${display}`};
   ${({ cursor }) => cursor && `cursor:pointer`};
`;
export default Typography;
