import styled from "styled-components";

const Container = styled.div`
  max-width: 1024px;
  display: flex;
  flex: 1;
  flex-direction: column;
  position:relative;

  @media only screen and (min-width: 320px) and (max-width: 800px) {
    width: 100% !important;
  }
`;

export default Container;
