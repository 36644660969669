import React from "react";
import styled from "styled-components";
import Separator from "../../components/template/Separator";

const Error = styled.span`
  color: red;
  font-family: "Avenir-Roman";
  font-size: 14px;
`;

const TextArea = styled.textarea`
  resize: none;
  width: 100%;
  height: 100px;
  font-family: "Avenir-Roman";
  font-size: 14px;
  outline: none;
  border: 1px solid rgba(221, 225, 235, 1);
`;

const TextBase = styled.input`
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid rgba(221, 225, 235, 1);
  outline: none;
  font-family: "Avenir-Roman";
  height:40px;
`;

const Label = styled.label`
  font-size: 14px;
  font-family: Avenir-Medium;

  ${({ labelColor }) => labelColor && `color:${labelColor}`};
`;

const Select = styled.select`
  height: 36px;
  width: 100%;
  font-size: 14px;
  font-family: "Avenir-Roman";
  padding: 8px;
  border-radius: 3px;
  font-size: 14px;
  border: 1px solid rgba(221, 225, 235, 1);
  outline: none;
  background-color: #ffffff;

  @media only screen and (min-width: 320px) and (max-width: 800px) {
    font-size: 13px;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, transparent, transparent);
    background-position: calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex:1;

  ${({ error }) =>
    error &&
    `
    input, select{
      border:1px solid red;
      color : red;
      
      &::placeholder {
        color: red;
      }
    }
  `};
`;

const TextInput = ({
  label,
  type,
  value,
  labelColor,
  onChange,
  placeholder = "",
  options = [],
  error,
  noLabel,
  noSeparator,
  ...rest
}) => {
  const RenderError = ({ error }) => {
    if (error && error !== "") {
      return (
        <React.Fragment>
          <Error>{error}</Error>
          <Separator size={10} />
        </React.Fragment>
      );
    }

    return <React.Fragment />;
  };

  const render = () => {
    if (type === "textarea") {
      return (
        <React.Fragment>
          <TextArea
            placeholder={placeholder}
            onChange={onChange}
            {...rest}
            value={value}
          />
          <RenderError error={error} />
        </React.Fragment>
      );
    }

    if (type === "select") {
      return (
        <Wrapper error={error}>
          <Label labelColor={labelColor}>{label}</Label>
          <Separator size={5} />
          <Select onChange={onChange}>
            {options &&
              options.length > 0 &&
              options.map((item) => (
                <option value={item.value}>{item.label}</option>
              ))}
          </Select>
          <Separator size={10} />
        </Wrapper>
      );
    }

    return (
      <Wrapper error={error}>
        {!noLabel && <Label labelColor={labelColor}>{label}</Label>}
        {!noSeparator && <Separator size={5} />}
        <TextBase
          value={value}
          type={type}
          onChange={onChange}
          placeholder={placeholder}
          {...rest}
        />
        {!noSeparator && <Separator size={10} />}
      </Wrapper>
    );
  };

  return render();
};

export default TextInput;
