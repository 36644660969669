import styled from "styled-components";

const Separator = styled.div`
  height: 40px;
  width: 30px;

  ${({ width }) =>
    width &&
    `
    width : ${width}px;
  `};

  ${({ size }) =>
    size &&
    `
    height:${size}px;
  `};

  ${({ small }) =>
    small &&
    `
        height : 20px;
    `};

  ${({ normal }) =>
    normal &&
    `
        height : 60px;
    `};

  ${({ large }) =>
    large &&
    `
        height : 80px;
    `};

  ${({ sizeInMobile }) =>
    sizeInMobile &&
    `
    @media only screen and (min-width:320px) and (max-width:768px){
      height : ${sizeInMobile}px !important;
    }
  `};
`;

export default Separator;
