import styled from "styled-components";

const Wrap = styled.div`
  position: relative;
  padding: 0.4rem;
  margin-bottom: 1rem;
  display:flex;
  border: 1px solid transparent;
  font-family: "Avenir-Roman";
  font-size: 15px;
  margin-top:20px;
  min-height:50px;
  display:flex;
  align-items:center;
  justify-content:center;

  ${({ warning }) =>
    warning &&
    `
        color: #856404;
        background-color: #fff3cd;
        border-color: #ffeeba;
    `};

  ${({ success }) =>
    success &&
    `
    color: #ffffff;
    background-color: rgb(117, 204, 150);
    `};

  ${({ error }) =>
    error &&
    `
    color: #ffffff;
    background-color: #f66826;

    `};
`;

export default Wrap;
