import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.a`
    font-family: Avenir-Roman;
    color: #0e264d;
    text-decoration:none;

    ${({ size }) =>
    size &&
    `
        font-size : ${size}px;
    `};

    ${({ underline }) =>
    underline &&
    `
        text-decoration : underline;
    `};

    ${({ color }) =>
    color &&
    `
    color:${color} !important;
   `};
`

const Link = ({ children, ...rest }) => {
    return (
        <Wrapper {...rest}>{children}</Wrapper>
    )
}

export default Link