import styled from 'styled-components'

const Button = styled.button`
  color: #ffffff;
  background-color: #dca149;
  text-transform: uppercase;
  font-family: 'Avenir-Roman';
  font-size: 15px;
  text-align: center;
  padding: 8px;
  outline: none;
  width: 130px;
  appearance: none;
  border: 0;
  cursor: pointer;
  line-height: 28px;
  height: 40px;

  ${({ noUpperCase }) => noUpperCase && `text-transform:none !important`};

  &:disabled {
    background-color: #CCCCCC !important;
    cursor: not-allowed;
  }

  &:hover {
    background-color: #e1af64;
  }

  ${({ padding }) => padding && `padding: ${padding}px;`};
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight};`};
  ${({ height }) => height && `height: ${height}`};

  ${({ width }) =>
    width &&
    `
    width : ${width} !important;
  `};

  ${({ borderRadius }) =>
    borderRadius &&
    `
    border-radius:5px;
  `};

  ${({ fontSize }) => fontSize && `font-size:${fontSize}px;`};
  ${({ normal }) => normal && `text-transform:initial;`};

  ${({ outline }) =>
    outline &&
    `
    background-color:transparent;
    color:#dca149;

    &:hover{
      background-color:#ffffff;
    }
  `};
`

export default Button
