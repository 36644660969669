import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import VerifyForm from "./pages/VerifyForm";
import ServiceForm from "./pages/ServiceForm";

function App() {
  return (
    <Router>
      <Route path={"/hidden-service-page0820"}>
        <ServiceForm />
      </Route>
      <Route exact path={"/"}>
        <VerifyForm />
      </Route>
    </Router>
  );
}

export default App;
