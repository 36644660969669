import React, { useState } from "react";
import "@aws-amplify/ui/dist/style.css";
import { Authenticator } from "aws-amplify-react";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import awsmobile from "../aws-exports";

import Header from "../components/template/Header";
import Container from "../components/template/Container";
import Column from "../components/template/Column";
import Typography from "../components/common/Typography";
import TextInput from "../components/form/TextInput";
import Button from '../components/form/Button'

Amplify.configure(awsmobile);

//
// *** GraphQL operations
const clearDB = async () => {
  // Get list of all doctors
  const gqlGetAllDoctors = `{ listDoctors(filter: {id:{ne:""}}, limit: 9999999) { items { id } } }`;

  const doctorIDList = await API.graphql(graphqlOperation(gqlGetAllDoctors))
    .then((result) => result && result.data && result.data.listDoctors.items)
    .catch((err) => []);

  if (doctorIDList.length === 0) return;

  // Delete each doctor from list
  const gqlDeleteTemplate = `deleteDoctor(input: { id: "##ID##" }) { full_name }`;
  const gqlDeleteRequests = doctorIDList.map(
    (doctor, index) =>
      `alias${index}: ${gqlDeleteTemplate.replace("##ID##", doctor.id)}`
  );
  const batchDelete = `mutation {\n${gqlDeleteRequests.join("\n")}\n}`;

  const deleteResult = await API.graphql(graphqlOperation(batchDelete))
    .then((result) => console.log(result))
    .catch((err) => []);
};

const insertList = async (textList) => {
  const doctorList = textList.split("\n");

  const gqlInsertTemplate = `createDoctor(input: { full_name: "##NAME##" } ) { full_name }`;
  const gqlInsertRequests = doctorList
    .filter((doctor) => doctor)
    .map((doctor, index) =>
      `alias${index}: ${gqlInsertTemplate.replace("##NAME##",doctor.toLowerCase() )}`
    );
  const batchInsert = `mutation {\n${gqlInsertRequests.join("\n")}\n}`;
  const insertResult = await API.graphql(graphqlOperation(batchInsert))
    .then((result) => console.log(result))
    .catch((err) => []);
};

const ServicePage = () => {
  const [authState, setAuthState] = useState("");
  const [csvContent, setCsvContent] = useState("");
  const [loading, setLoading] = useState(false);

  const handleStateChange = async (status) => {
    setAuthState(status);
  };

  const getAllDoctors = async () => {
    setLoading(true)
    const gqlGetAllDoctors = `{ listDoctors(filter: {id:{ne:""}}, limit: 9999999) { items { full_name } } }`;
    const doctorIDList = await API.graphql(graphqlOperation(gqlGetAllDoctors))
      .then((result) => result && result.data && result.data.listDoctors.items)
      .catch((err) => []);
    
    let data = "";
    let index = 0;
    doctorIDList.forEach(doctor => {
      data += index > 0 ? `\n${doctor.full_name}` : `${doctor.full_name}`
      index++;
    })
    setCsvContent(data)
    setLoading(false)
  }

  React.useEffect(() => {
    getAllDoctors()
  },[authState])

  const handleSubmitClick = async () => {
    setLoading(true)
    await clearDB();
    await insertList(csvContent);
    setLoading(false)
  };

  const title = authState === "signedIn" ? '' : 'Login'

  return (
    <Container>
        <Header title={title} />
        <Column
          hideInDesktop
          showInMobile
          flexCenter
          width={"100%"}
          align={"center"}
      >
          <Typography mobileSize={18} center titleSection bold>
              {title}
          </Typography>
        </Column>
        <Authenticator
          hideDefault={false}
          onStateChange={handleStateChange}
        ></Authenticator>
      

      {authState === "signedIn" && (
        <div>
          <TextInput 
            type={'textarea'}
            style={{ width: "100%", height: "500px" }}
            defaultValue={csvContent}
            onChange={(evt) => {
              setCsvContent(evt.target.value);
            }}
          />
          <Button disabled={loading} style={{width:'100%'}} onClick={handleSubmitClick}>{loading ? 'Please wait' : 'Update'}</Button>
        </div>
      )}
    </Container>
  );
};

export default ServicePage;
