import "core-js";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

//import API from "@aws-amplify/api";
//import awsconfig from "./aws-exports";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createGlobalStyle } from "styled-components";

import AvenirBlack from "./assets/fonts/Avenir-Black.ttf";
import AvenirMedium from "./assets/fonts/AvenirLTStd-Medium.ttf";
import AvenirRoman from "./assets/fonts/AvenirLTStd-Roman.ttf";

import AvenirRomanWoff from "./assets/fonts/AvenirLTStd-Roman.woff";
import AvenirMediumWoff from "./assets/fonts/AvenirLTStd-Medium.woff";
import AvenirBlackWoff from "./assets/fonts/Avenir-Black.woff";

import AvenirItalic from './assets/fonts/Avenir-Italic.ttf';

const GlobalStyle = createGlobalStyle`
  * , *:before, *:after{ 
    box-sizing:border-box; 
    -moz-box-sizing:border-box; 
    -webkit-box-sizing:border-box; 
    -ms-box-sizing:border-box;
  }

  @font-face {
    font-family: 'Avenir-Italic';
    src:local('Avenir-Italic'), url(${AvenirItalic}) format('truetype');
  }

  @font-face {
    font-family: 'Avenir-Black';
    src: local('Avenir-Black'), url(${AvenirBlack}) format('truetype');
    src: url(${AvenirBlackWoff}) format('woff');
  }

  @font-face {
    font-family: 'Avenir-Medium';
    src: local('Avenir-Medium'), url(${AvenirMedium}) format('truetype');
    src: url(${AvenirMediumWoff}) format('woff');
  }

  @font-face {
    font-family: 'Avenir-Roman';
    src: local('Avenir-Roman'), url(${AvenirRoman}) format('truetype');
    src: url(${AvenirRomanWoff}) format('woff');
  }

  .Section__container___3YYTG{
    width:100%;
    font-family: 'Avenir-Roman';
  }
  
  body{
    padding:0;
    margin:0;
    display:flex;
    flex:1;
    width:100%;
    justify-content:center;
  }

  #root{
    display:flex;
    width:640px;
    padding : 10px;
    font-family: 'Avenir-Roman';
  }

  div > br{
    display: block;
    margin: 8px 0 8px 0;
  }

  div > italic{
    font-family:'Avenir-Italic';
    line-height: 17px;
  }

  div > strong{
    font-family:'Avenir-Medium';
  }

  a{
    text-decoration:underline;
    color : #ffffff;
    font-size:15px;

    @media only screen and (min-width:320px) and (max-width:800px){
      font-size:13px;
    }
  }

  .fadeOut{
    opacity:0;
    transition:all 300ms ease;
    height:0;
  }
  
  .fadeIn{
    opacity:1;
    transition:all 300ms ease;
    height: auto;
  }

  @media only screen and (min-width:320px) and (max-width:767px){
    #root{
      width:100% !important;
      padding:0;
    }

    .fadeIn{
      height: 90px;
    }
  }

  .Nav__navItem___1LtFQ{
    display:none !important;
  }

  
`;

// Configure Amplify
//API.configure(awsconfig);

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
